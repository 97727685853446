<template>
	<Layout :footer-style="2" :header-transparency="true">
		<div class="slider-area bg-transparent slider-style-1">
			<div class="eser-container">
				<div class="section-content">
					<div id="content-c7333548" class="frame frame-default frame-type-text frame-layout-0 skin-mt-small">
						<h1 class="align-center">为企业的未来做好网络安全准备</h1>
						<h3 class="align-center">受到分析师和客户的信任</h3>
					</div>
					<div id="content" class="csc-frame csc-frame-default">
						<div class="row flex skin-padding-xl skin-mb-s skin-mt-l">
							<div class="col col-xs-6 col-sm-2-4 ">
								<div id="content-c7333595" class="frame frame-default frame-type-esetbase_fce_svgicons frame-layout-0">
									<div class="ce-textpic textpic-svg ce-center ce-above">
										<div class="eset-svg-icon 0 color-faded-white " style="">
											<div class="svg-holder" style="width:150px; height: 105px">
												<img src="@/assets/images/_new/mitsubishi_motors.png">
											</div>
										</div>
										<div class="ce-bodytext">
											<p class="align-center description"><strong>受ESET保护 自2017年以来 9,000多个端点</strong></p>
										</div>
									</div>
								</div>
							</div>
							<div class="col col-xs-6 col-sm-2-4 ">
								<div id="content-c7333592" class="frame frame-default frame-type-esetbase_fce_svgicons frame-layout-0">
									<div class="ce-textpic textpic-svg ce-center ce-above">
										<div class="eset-svg-icon 0 color-faded-white " style="">
											<div class="svg-holder" style="width:150px; height: 105px">
												<img src="@/assets/images/_new/allianz-suisse-white.png">
											</div>
										</div>
										<div class="ce-bodytext ">
											<p class="align-center description"><strong>受ESET保护 自2016年以来 4,000多个邮箱</strong></p>
										</div>
									</div>
								</div>
							</div>
							<div class="col col-xs-6 col-sm-2-4 ">
								<div id="content-c7333605" class="frame frame-default frame-type-esetbase_fce_svgicons frame-layout-0">
									<div class="ce-textpic textpic-svg ce-center ce-above">
										<div class="eset-svg-icon 0 color-faded-white " style="">
											<div class="svg-holder" style="width:150px; height: 105px">
												<img src="@/assets/images/_new/canon.png">
											</div>
										</div>
										<div class="ce-bodytext ">
											<p class="align-center description"><strong>受ESET保护 自2016年以来 超过14,000个端点</strong></p>
										</div>
									</div>
								</div>
							</div>
							<div class="col col-xs-6 col-sm-2-4 ">
								<div class="hidden-from-to-xxs hidden-from-to-xs hidden-from-to-sm">
									<div id="content-c7333618" class="frame frame-default frame-type-esetbase_fce_svgicons frame-layout-0">
										<div class="ce-textpic textpic-svg ce-center ce-above">
											<div class="eset-svg-icon 0 color-faded-white " style="">
												<div class="svg-holder" style="width:150px; height: 105px">
													<img src="@/assets/images/_new/tcom.png">
												</div>
											</div>
											<div class="ce-bodytext ">
												<p class="align-center description"><strong>ISP安全合作伙伴 自2008年以来 200万客户群</strong></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="blog-details-content pt--20 pb--20">
			<div class="container">
				<div class="row">
					<div class="col-lg-10 offset-lg-1 detail-content" data-aos="fade-up" data-aos-delay="100">
						<div class="title">为什么企业欣赏我们的安全解决方案</div>
						<div style="margin-top: 40px">
							<img src="@/assets/images/_new/eset2.png">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="blog-details-content pt--20 pb--20 tbg">
			<div class="container">
				<div class="row">
					<div class="col-lg-10 offset-lg-1 detail-content" data-aos="fade-up" data-aos-delay="100">
						<div class="title" style="color: white">ESET独特组合技术的三大支柱</div>
						<div style="margin-top: 40px" class="row">
							<div class="col col-sm-4 ">
								<div class="frame frame-default frame-type-image frame-layout-0">
									<div class="ce-image ce-center ce-above">
										<div class="ce-gallery" data-ce-columns="1" data-ce-images="1">
											<div class="ce-outer">
												<div class="ce-inner">
													<div class="ce-row">
														<div class="ce-column">
															<img src="@/assets/images/_new/live-grid.png" title="" alt="" loading="lazy" width="200" height="200">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="frame frame-default frame-type-text frame-layout-0">
									<header><h4 class="align-center bold"> LiveGrid® </h4></header>
									<p class="align-center"> ESET LiveGrid®是一个预防系统，从世界各地的ESET用户那里反馈有关威胁的信息。LiveGrid®数据库包含有关潜在威胁的信誉信息，检测和阻止最近引入的威胁，使其对快速变化的威胁非常有效。</p>
								</div>
							</div>
							<div class="col col-sm-4 " style="">
								<div class="frame frame-default frame-type-image frame-layout-0">
									<div class="ce-image ce-center ce-above">
										<div class="ce-gallery" data-ce-columns="1" data-ce-images="1">
											<div class="ce-outer">
												<div class="ce-inner">
													<div class="ce-row">
														<div class="ce-column">
															<img src="@/assets/images/_new/machine-learning.png" title="" alt="" loading="lazy" width="200" height="200">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="frame frame-default frame-type-text frame-layout-0">
									<header><h4 class="align-center bold"> 机器学习 </h4></header>
									<p class="align-center"> ESET的机器学习引擎使用神经网络（深度学习和长短期记忆）和精心挑选的算法相结合，以产生综合输出，并正确地将传入的样本标记为干净的、潜在不受欢迎的或恶意的。</p>
								</div>
							</div>
							<div class="col col-sm-4 ">
								<div class="frame frame-default frame-type-image frame-layout-0">
									<div class="ce-image ce-center ce-above">
										<div class="ce-gallery" data-ce-columns="1" data-ce-images="1">
											<div class="ce-outer">
												<div class="ce-inner">
													<div class="ce-row">
														<div class="ce-column">
															<img src="@/assets/images/_new/human-expertise.png" title="" alt="Human expertise" loading="lazy" width="200" height="200">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="frame frame-default frame-type-text frame-layout-0">
									<header><h4 class="align-center bold"> 人类的专业知识 </h4></header>
									<p class="align-center">我们分布在全球13个研发中心的世界级安全研究人员，实时分享我们的精英知识和情报，以确保全天候的威胁情报。在我们的企业博客和welivesecurity.com中阅读他们对网络安全新闻的专家分析。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="blog-details-content pt--20 pb--20 gbg">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<div class="frame frame-default frame-type-text frame-layout-0" style="margin-top: 80px">
							<header><h3 class="bold"> 你身边的网络安全专家。 </h3></header>
							<ul class="list-dotmark list-skin-gray-blue">
								<li>保持 <strong>30年行业领先的IT安全创新</strong>，自20世纪90年代以来，ESET在全球不断扩展业务。</li>
								<li>ESET的管理者是 <strong>网络安全专家</strong> ，也是软件的 <strong>创始人。</strong>.我们是为数不多的全球私营IT安全公司之一。 </li>
								<li>超过 <strong>1.1亿用户和40万企业客户</strong> 在200多个国家和地区，他们选择并信任ESET安全解决方案。</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="blog-details-content pt--20 pb--20">
			<div class="container">
				<div class="row">
					<div class="col-lg-10 offset-lg-1 detail-content" data-aos="fade-up" data-aos-delay="100">
						<div class="title">面向企业的解决方案</div>
						<div class="content">
							符合所有安全要求。灵活和可扩展适用于任何IT环境
						</div>
						<div style="margin-top: 40px">
							<img src="@/assets/images/_new/eset4.png">
						</div>
					</div>
				</div>
			</div>

		</div>
	</Layout>
</template>

<script>
import Layout from '@/components/common/Layout.vue'
import Icon from '@/components/icon/Icon.vue'
import DetailMixin from "@/mixins/DetailMixin";
import VueSlickCarousel from 'vue-slick-carousel'
import * as url from "url";

export default {
	name: 'ServiceDetail',
	computed: {
		url() {
			return url
		}
	},
	components: {Icon, Layout, VueSlickCarousel},
	mixins: [DetailMixin],
	data() {
		return {
			sliderSetting: {
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 1,
						}
					},
					{
						breakpoint: 993,
						settings: {
							slidesToShow: 1,
						}
					},
					{
						breakpoint: 580,
						settings: {
							slidesToShow: 1,
						}
					},
					{
						breakpoint: 481,
						settings: {
							slidesToShow: 1,
						}
					}
				]
			},
		}
	},
	methods: {
	},
	mounted() {
		// this.getPost(this.id);
	}
}
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../../assets/images/_new/enterprise-town-overlay.png");
	height: 580px;
	.eser-container {
		padding-top: 100px;
		padding-bottom: 50px;
		width: 800px;
		.section-content {
			vertical-align: middle;
			width: 100%;

			.skin-mt-l, .skin-mt-s, .skin-mt-small {
				margin-top: 25px;
			}
		}

		.align-center {
			text-align: center;
		}
		.csc-frame {
			margin-top: 60px;
		}
		h1 {
			font-size: 45px;
			line-height: 1.11111em;
			margin-bottom: 35px;
			color: #fff !important;
			font-weight: 200;
		}
		h3 {
			font-size: 26px;
			line-height: 1.34615em;
			margin-bottom: 15px;
			color: #fff !important;
			font-weight: 200;
		}
		.eset-svg-icon [fill^="#"]{
			fill: white;
		}
		.eset-svg-icon .svg-holder {
			position: relative;
			height: 0;
			max-width: 100%;
			margin-bottom: 15px;
			line-height: 105px;
			display: block;
			z-index: 0;
		}
		.ce-center {
			text-align: center;
		}
		.ce-textpic .eset-svg-icon {
			display: inline-block;
		}
		.ce-above .ce-bodytext {
			clear: both;
			.description {
				text-align: center;
				color: #fff;
			}
		}
	}
}

.tbg {
	background-image: url("../../assets/images/_new/enterprise-technology-background.png");
}
.gbg {
	background-image: url("../../assets/images/_new/map-gray-right.jpg");
	height: 450px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}
.detail-content {
	margin-bottom: 30px;
	font-size: 16px;
	text-align: center;
	.title{
		text-align: center;
		font-weight: 200;
		color: black;
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 35px;
	}
	.content {
		font-size: 20px;
		line-height: 1.3em;
		margin-bottom: 10px;
		font-weight: 200;
	}
}
p {
	margin-bottom: 20px;
}
.eset-card {
	padding: 25px;
	color: #424d56;
	background: #f4f5f4;
	.title {
		font-size: 13px;
		line-height: 1.23077em;
		font-weight: 500;
	}
	.svg-holder {
		margin-top: 12px;
	}
	.description {
		font-size: 15px;
		line-height: 1.33333em;
	}
	h4 {
		font-weight: 200;
		font-size: 20px;
		line-height: 1.3em;
		margin-bottom: 10px;
		margin-top: 16px;
	}
}
:deep(.container-sm) {width: 1000px}
.height-450 {
	height: 280px;
}
.frame-type-text {
	color: white;
	h4 {
		color: white;
		font-size: 20px;
		line-height: 1.3em;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	p {
		color: white;
		font-size: 16px;
	}
}
</style>
