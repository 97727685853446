<template>
	<Layout :footer-style="2" :header-transparency="true">
		<div class="slider-area bg-transparent slider-style-1"></div>

			<div id="cloud-safety" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/solution1.png" alt="云安全解决方案">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										云安全解决方案
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										DDOS高防IP防御服务，更安全，更稳定；大带宽多线BGP节点集群，资源保障充足多线分布式防御系统不用SDK实现盾的防CC效果。
										<br>
										核心产品包括：DDOS高防IP、WEB应用防火墙、DDOS防火墙。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/solution/cloud-safety')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="mail-safety" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										邮件安全解决方案
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘结合多年的安全咨询服务经验，提出了基础设施—安全体系—态势感知的安全金字塔建设理念，帮助客户逐步实现对安全风险的闭环管理和智能管控。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/solution/mail-safety')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/solution2.png" alt="邮件安全解决方案">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="device-safety" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/solution3.png" alt="终端安全解决方案">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										终端安全解决方案
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										提供多因素身份认证和动态身份认证能力<br>
										提供对终端环境的多层次安全评估能力<br>
										通过应用深层解析能力防护已知或未知漏洞<br>
										依托业务代理网关可提供业务敏感数据识别能力<br>
										基于身份的溯源分析能力，精准还原攻击行为<br>
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/solution/device-safety')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import Icon from '../components/icon/Icon.vue'
    import Separator from '../components/elements/separator/Separator.vue'
		import Button from "@/components/elements/button/Button.vue";

    export default {
        name: 'Home',
        components: {Button, Icon, Layout, Separator},
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../assets/images/_new/banner5.png");
}
.blue-title {
	font-size: 34px;
	color: #102e98;
	line-height: 34px;
	font-weight: 400;
}
.desc{
	font-size: 20px;
	line-height: 36px;
	color: #666;
	margin: 35px 0 25px;
}
</style>
