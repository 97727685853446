import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Home.vue'
import Protection from '../views/Protection.vue'
import Service from '../views/Service.vue'
import ServiceDetail from '../views/Detail.vue'
import Solution from '../views/Solution.vue'
import Eset1 from '../views/eset/small-and-medium.vue'
import Eset2 from '../views/eset/enterprise.vue'

import About from '../views/About.vue'
import ErrorPage from '../views/404'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'Demo',
        component: Demo,
        meta: {
            title: '首页 - 安炘嘉得',
        },
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution,
        meta: {
            title: '产品解决方案 - 安炘嘉得',
        },
    },
    {
        path: '/level-protection',
        name: 'Protection',
        component: Protection,
        meta: {
            title: '等级保护 - 安炘嘉得',
        },
    },
    {
        path: '/level-protection/:id',
        name: 'Detail',
        component: ServiceDetail,
        meta: {
            title: '详情',
        }
    },
    {
        path: '/solution/:id',
        name: 'Detail',
        component: ServiceDetail,
        meta: {
            title: '详情',
        }
    },
    {
        path: '/eset',
        name: 'Eset',
        component: Eset1,
        meta: {
            title: 'ESET企业网络安全解决方案',
        }
    },
    {
        path: '/eset/enterprise',
        name: 'Eset2',
        component: Eset2,
        meta: {
            title: 'ESET企业网络安全解决方案',
        }
    },
    {
        path: '/eset/small-and-medium',
        name: 'Eset',
        component: Eset1,
        meta: {
            title: 'ESET企业网络安全解决方案',
        }
    },
    {
        path: '/service',
        name: 'Service',
        component: Service,
        meta: {
            title: '安全咨询与服务 - 安炘嘉得',
        }
    },
    {
        path: '/service/:id',
        name: 'ServiceDetail',
        component: ServiceDetail,
        meta: {
            title: '详情',
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们 - 安炘嘉得',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '安炘嘉得',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
